body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  margin: auto;
  max-width: 60rem;
}

@font-face {
  font-family: "Roboto";
  src: url(Roboto-Regular.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  line-break: auto;
}

body {
  background-color: #343541;
  color: #ffff;
  height: 100vh;
  width: 100vw;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cursor {
  width: 20px;
  height: 25px;
  background-color: rgb(232, 221, 221);
  animation: blink 1.2s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1000px) {
  .loader_columns {
    flex-direction: column !important;
    align-items: center;
  }

  .input_wrapper {
    background-color: rgb(60, 59, 59);
    width: 100% !important;
    padding: 10px;
    margin: 10px;
  }
  .initial_loader_wrapper {
    width: 100% !important;
  }

  .messages_wrapper {
    width: 98% !important;
  }
}

@media screen and (max-width: 320px) {
  .main_container {
    width: 99% !important;
  }

  .input_wrapper {
    background-color: rgb(60, 59, 59);
    padding-top: 10px;
    width: 100% !important;
  }
  .initial_loader_wrapper {
    width: 100% !important;
  }
  .messages_wrapper {
    width: 98% !important;
  }
}
